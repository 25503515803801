import Crud from '../crud'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
import Vue from 'vue'
var crud = new Crud('external-distributors')

const state = {
  ...crud.state,
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
}

const mutations = {
  ...crud.mutations,
}

export default {
  state,
  getters,
  actions,
  mutations
}
